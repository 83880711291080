import {
  SaveFilled,
  FileImageFilled,
  AudioOutlined,
  FileImageOutlined,
  VideoCameraFilled,
  VideoCameraOutlined,
  UserSwitchOutlined,
  EditOutlined, SoundOutlined, StepForwardOutlined, EditFilled,
} from '@ant-design/icons-vue';
import UserOutlined, {
  UserOutlinedIconType,
} from '@ant-design/icons-vue/lib/icons/UserOutlined';
import { createRouter, createWebHistory } from 'vue-router';
import type { RouteRecordRaw } from 'vue-router';
import { useCookies } from 'vue3-cookies';

type IRouteRecordSingleView = RouteRecordRaw & {
  label?: string;
  icons?: UserOutlinedIconType[] | UserOutlinedIconType;
  children?: Array<IRouteRecordSingleView>;
};

export const routes: Array<IRouteRecordSingleView> = [
  {
    path: '/',
    redirect: '/original/image',
    component: () => import('layouts/MainLayout.vue'),
  },
  {
    path: '/sign-in',
    name: 'SignIn',
    component: () => import('pages/signIn/Index.vue'),
  },
  {
    label: 'File Upload',
    path: '/original',
    component: () => import('layouts/MainLayout.vue'),
    icons: [SaveFilled],
    children: [
      {
        path: '/original/image',
        name: 'UploadImage',
        component: () => import('pages/original/image/Index.vue'),
        props: { apiUrl: 'images/' },
        label: 'Image',
        icons: [FileImageOutlined],
      },
      {
        path: '/original/video',
        name: 'UploadVideo',
        component: () => import('pages/original/video/Index.vue'),
        props: { apiUrl: 'video/' },
        label: 'Video',
        icons: [VideoCameraOutlined],
      },
      {
        path: '/original/audio',
        name: 'UploadAudio',
        component: () => import('pages/original/audio/Index.vue'),
        props: { apiUrl: 'audios/' },
        label: 'Audio',
        icons: [AudioOutlined],
      },
    ],
  },
  {
    label: 'Image Result',
    path: '/result/image',
    icons: [FileImageFilled],
    component: () => import('layouts/MainLayout.vue'),
    children: [
      {
        path: '/result/image/virtual-face',
        name: 'VirtualFace',
        component: () => import('pages/results/images/virtualFace/Index.vue'),
        props: { apiUrl: 'image-results/virtual-skins/' },
        label: 'virtual-face',
        icons: [UserOutlined, UserSwitchOutlined],
      },
      {
        path: '/result/image/virtual-face-text',
        name: 'VirtualSkinText',
        component: () =>
          import('pages/results/images/vitualFaceText/Index.vue'),
        props: { apiUrl: 'image-results/virtual-skin-texts/' },
        label: 'virtual-face-text',
        icons: [UserOutlined, EditOutlined],
      },
    ],
  },
  {
    label: 'Video Result',
    path: '/result/video',
    component: () => import('layouts/MainLayout.vue'),
    icons: [VideoCameraFilled],
    children: [
      {
        path: '/result/video/lip',
        name: 'LipResult',
        component: () => import('pages/results/videos/lipResult/Index.vue'),
        props: { apiUrl: 'video-results/lips/', scenarioUrl: 'scenarios/lips' },
        label: 'change-process-lip',
        icons: [VideoCameraOutlined, AudioOutlined],
      },
      {
        path: '/result/video/face',
        name: 'Face',
        component: () => import('pages/results/videos/fastTrack/Index.vue'),
        props: {
          apiUrl: 'video-results/faces/',
          scenarioUrl: 'scenarios/faces',
        },
        label: 'change-process-face',
        icons: [VideoCameraOutlined, UserOutlined],
      },
      {
        path: '/result/video/lip-face',
        name: 'Lip Face',
        component: () => import('pages/results/videos/lipFaceResult/Index.vue'),
        props: {
          apiUrl: 'video-results/lip-faces/',
          scenarioUrl: 'scenarios/lip-faces',
        },
        label: 'change-process-lip-face',
        icons: [VideoCameraOutlined, UserOutlined, AudioOutlined],
      },
      {
        path: '/result/video/reenact',
        name: 'Reenact',
        component: () => import('pages/results/videos/reenact/Index.vue'),
        props: {
          apiUrl: 'video-results/reenacts/',
          scenarioUrl: 'scenarios/reenacts',
        },
        label: 'Reenact',
        icons: [VideoCameraOutlined, UserOutlined],
      },
      {
        path: '/result/video/kiosk',
        name: 'Kiosk',
        component: () => import('pages/results/videos/kiosk/Index.vue'),
        props: {
          apiUrl: 'video-results/kiosks/',
          scenarioUrl: 'scenarios/lips/kiosk',
        },
        label: 'Kiosk',
        icons: [VideoCameraOutlined, UserOutlined],
      },
    ],
  },
  {
    label: 'Audio Result',
    path: '/result/audio',
    icons: [SoundOutlined],
    component: () => import('layouts/MainLayout.vue'),
    children: [
      {
        path: '/result/audio/tts',
        name: 'TTS',
        component: () => import('pages/results/audios/TTS/Index.vue'),
        props: {
          apiUrl: 'audio-results/tts/',
          scenarioUrl: 'scenarios/tts',
        },
        label: 'tts',
        icons: [EditFilled, StepForwardOutlined],
      },
    ],
  },
  // Always leave this as last one,
  // but you can also remove it
  {
    path: '/:catchAll(.*)*',
    component: () => import('pages/Error404.vue'),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach(function (to, from, next) {
  const { cookies } = useCookies();
  const previewerAccessToken = cookies.get('previewerAccessToken');

  if (to.name !== 'SignIn' && !previewerAccessToken) {
    next('/sign-in');
  } else if (to.name === 'SignIn' && previewerAccessToken) {
    next('/');
  } else {
    next();
  }
  // to : 이동할 url
  // from : 현재 url
  // next : to에서 지정한 url로 이동하기 위해 꼭 호출해야 하는 함수
});

export default router;

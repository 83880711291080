import { createApp } from 'vue';

import Antd from 'ant-design-vue';
import VueAxios from 'vue-axios';
import VueCookies from 'vue3-cookies';

import axios from '@/utils/axios';

import App from './App.vue';
import 'ant-design-vue/dist/antd.css';
import './registerServiceWorker';
import router from './router';
import store from './store';

createApp(App)
  .use(Antd)
  .use(router)
  .use(VueAxios, axios)
  .use(VueCookies, { expireTimes: -1 })
  .use(store)
  .mount('#app');
